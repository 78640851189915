import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Helmet from 'react-helmet';
import axios from 'axios';
import Layout from '../components/Layout';
import styles from '../css/contactus.module.css';
import PageHeading from '../components/PageHeading/PageHeading';
import TitleBar from '../components/TitleBar/TitleBar';
import Recaptcha from 'react-recaptcha';

const BchImg = props => {
  const imgStyle = {
    maxWidth: '150px',
    maxHeight: '150px',
    borderRadius: '75px',
  };

  return (
    <StaticQuery
      query={graphql`
        query ContactImageQuery {
          imgChaBinh: file(relativePath: { eq: "BCHTU/chaBinh.jpg" }) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          imgChaThong: file(relativePath: { eq: "BCHTU/chaThong.jpg" }) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          imgPNH: file(relativePath: { eq: "BCHTU/trKhanh.png" }) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          imgCT: file(relativePath: { eq: "BCHTU/trDuong.jpg" }) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          imgPQT: file(relativePath: { eq: "BCHTU/ngoHieu.jpg" }) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          imgThuQuy: file(relativePath: { eq: "BCHTU/trLap.jpg" }) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }

          imgThuKy: file(relativePath: { eq: "BCHTU/trAnhThu.jpg" }) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => (
        <Img
          fixed={data[props.name].childImageSharp.fixed}
          style={{}}
          imgStyle={imgStyle}
        />
      )}
    />
  );
};

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameValid: true,
      doan: '',
      region: '',
      email: '',
      emailValid: true,
      subject: '',
      subjectValid: true,
      text: '',
      textValid: true,
      buttonDisabled: true,
      buttonClicked: false,
      validate: {},
      formSentSuccess: false,
      buttonText: 'Submit',
      formAllValid: false,
      captchaVerified: false,
    };
  }

  handleFormDataChange = evt => {
    const target = evt.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  validateEmail = e => {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state;
    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success';
    } else {
      validate.emailState = 'has-danger';
    }
    this.setState({ validate, emailValid: emailRex.test(e.target.value) }, () =>
      this.validateForm()
    );
  };

  validateName = e => {
    const { validate } = this.state;

    const nameValid = e.target.value !== '';
    if (nameValid) {
      validate.nameState = 'has-success';
    } else {
      validate.nameState = 'has-danger';
    }
    this.setState({ validate, nameValid: nameValid }, () =>
      this.validateForm()
    );
  };
  validateSubject = e => {
    const { validate } = this.state;

    const subjectValid = e.target.value !== '';
    if (subjectValid) {
      validate.subjectState = 'has-success';
    } else {
      validate.subjectState = 'has-danger';
    }
    this.setState({ validate, subjectValid: subjectValid }, () =>
      this.validateForm()
    );
  };
  validateText = e => {
    const { validate } = this.state;

    const textValid = e.target.value !== '';
    if (textValid) {
      validate.textState = 'has-success';
    } else {
      validate.textState = 'has-danger';
    }
    this.setState({ validate, textValid: textValid }, () =>
      this.validateForm()
    );
  };

  submitForm = e => {
    e.preventDefault();
    // validate fields
    // send email
    // disable button
    if (this.validateForm()) {
      this.setState({ buttonDisabled: true, buttonText: 'Sending...' });
      if (this.state.captchaVerified === true) {
        this.sendEmail();
      } else {
        console.log('capcha not verified');
      }
    } else {
      this.setState({ buttonDisabled: true });
    }
  };

  validateForm = () => {
    const { name, emailValid, subject, text } = this.state;
    const allValid = name !== '' && emailValid && subject !== '' && text !== '';

    this.setState({ formAllValid: allValid, buttonDisabled: !allValid });
    return allValid;
  };

  sendEmail = () => {
    const { email, name, text, subject } = this.state;
    axios({
      method: 'post',
      url:
        'https://us-central1-veym-build-trigger.cloudfunctions.net/sendEmail',
      data: {
        to: 'headquarters@veym.net',
        from: email.trim(),
        subject: `[VEYM.net Contact Form] ${subject.trim()}`,
        text: `${text.trim()} \n\n Name: ${name.trim()}`,
      },
    })
      .then(response => {
        console.log('contact email sent');
        this.setState({
          buttonText: 'Sent',
          buttonDisabled: true,
          formSentSuccess: true,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    const {
      name,
      email,
      subject,
      text,
      buttonDisabled,
      validate,
      captchaVerified,
      formSentSuccess,
    } = this.state;

    const buttonDisabledWithCapcha =
      buttonDisabled || !captchaVerified || formSentSuccess;

    return (
      <Layout>
        <Helmet
          title="VEYM | Contact"
          meta={[
            {
              name: 'description',
              content:
                'VEYM Contact Us - Questions? Concerns? Feedback? Contact VEYM Headquarters!',
            },
          ]}
        />
        <PageHeading>Contact Us</PageHeading>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Contact</BreadcrumbItem>
          </Breadcrumb>
        </Container>

        <TitleBar>Chaplains and Officers</TitleBar>
        <br />
        <Container>
          <Row>
            <Col>
              <Row className="justify-content-center">
                <Col align="center">
                  <BchImg name="imgChaBinh" />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <p>
                  <b>Rev. Binh Nguyen, SVD</b>
                </p>
              </Row>
              <Row className="justify-content-center">
                <p>General Chaplain</p>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="justify-content-center">
                <BchImg name="imgChaThong" />
              </Row>
              <Row className="justify-content-center">
                <p>
                  <b>Rev. Thong Ngo, CSsR</b>
                </p>
              </Row>
              <Row className="justify-content-center">
                <p>Assistant General Chaplain</p>
              </Row>
            </Col>
          </Row>
          <Row>
            {/* use size 2 offset 1 for 5 pictures */}
            <Col lg={{ size: 2, offset: 1 }} sm={12} align="center">
              <Row className="justify-content-center">
                <BchImg name="imgCT" />
              </Row>
              <Row className="justify-content-center">
                <p>
                  <b>Dr. Duong Hoang</b>
                </p>
              </Row>
              <Row className="justify-content-center">
                <p>President of the National Executive Committee</p>
              </Row>
            </Col>
            <Col lg={{ size: 2 }} sm={12} align="center">
              <Row className="justify-content-center">
                <BchImg name="imgPQT" />
              </Row>
              <Row className="justify-content-center">
                <p>
                  <b>Ms. Hieu Ngo</b>
                </p>
              </Row>
              <Row className="justify-content-center">
                <p>Vice President of Administrative Affairs</p>
              </Row>
            </Col>
            <Col lg={{ size: 2 }} sm={12} align="center">
              <Row className="justify-content-center">
                <BchImg name="imgPNH" />
              </Row>
              <Row className="justify-content-center">
                <p>
                  <b>Mr. Khanh Dao</b>
                </p>
              </Row>
              <Row className="justify-content-center">
                <p>Vice President of Academic Affairs</p>
              </Row>
            </Col>
            <Col lg={{ size: 2 }} sm={12} align="center">
              <Row className="justify-content-center">
                <BchImg name="imgThuKy" />
              </Row>
              <Row className="justify-content-center">
                <p>
                  <b>Ms. Anh Thu Nguyen</b>
                </p>
              </Row>
              <Row className="justify-content-center">
                <p>General Secretary</p>
              </Row>
            </Col>
            <Col lg={{ size: 2 }} sm={12} align="center">
              <Row className="justify-content-center">
                <BchImg name="imgThuQuy" />
              </Row>
              <Row className="justify-content-center">
                <p>
                  <b>Mr. Lap Vu</b>
                </p>
              </Row>
              <Row className="justify-content-center">
                <p>General Treasurer</p>
              </Row>
            </Col>
          </Row>
        </Container>
        <br />

        <TitleBar>Headquarters Office</TitleBar>
        <Container>
          <br />
          <Row className="justify-content-center">
            <b>1811 E Center St., Anaheim, CA 92805</b>
          </Row>
          <Row className="justify-content-center">
            <b>Email: headquarters@veym.net</b>
          </Row>
          <Row className="justify-content-center">
            <b>Phone: 714-603-7586</b>
          </Row>
          <br />
        </Container>
        <br />

        <TitleBar>VEYM IT Support</TitleBar>
        <Container>
          <br />
          <Row className="justify-content-center">
            Support for VEYM IT services
          </Row>
          <Row className="justify-content-center">Email: support@veym.net</Row>
          <Row className="justify-content-center">
            Or submit a ticket at&nbsp;
            <a
              href="https://support.veym.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://support.veym.net
            </a>
          </Row>

          <br />
        </Container>

        <br />
        <TitleBar>Contact Form</TitleBar>
        <Container>
          <div>
            <br />
            Use this form to send a message to the National Executive Committee.
            <br />
            <br />
            <Form onSubmit={this.submitForm}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="formName">Name</Label>
                    <Input
                      type="text"
                      name="name"
                      id="formName"
                      placeholder="Enter your name"
                      value={name}
                      onChange={this.handleFormDataChange}
                      onBlur={this.validateName}
                      valid={validate.nameState === 'has-success'}
                      invalid={validate.nameState === 'has-danger'}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="formEmail">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="formEmail"
                      placeholder="Enter your email"
                      value={email}
                      onChange={this.handleFormDataChange}
                      valid={validate.emailState === 'has-success'}
                      invalid={validate.emailState === 'has-danger'}
                      onBlur={this.validateEmail}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label for="formSubject">Subject</Label>
                <Input
                  type="text"
                  name="subject"
                  id="formSubject"
                  placeholder="Subject"
                  value={subject}
                  onChange={this.handleFormDataChange}
                  onBlur={this.validateSubject}
                  valid={validate.subjectState === 'has-success'}
                  invalid={validate.subjectState === 'has-danger'}
                />
              </FormGroup>
              <FormGroup>
                <Label for="formText">Message</Label>
                <Input
                  type="textarea"
                  name="text"
                  id="formText"
                  placeholder="Enter Message"
                  value={text}
                  onChange={this.handleFormDataChange}
                  onBlur={this.validateText}
                  valid={validate.textState === 'has-success'}
                  invalid={validate.textState === 'has-danger'}
                />
              </FormGroup>
              <Recaptcha
                sitekey="6LcOW5EUAAAAAEZv-IxD8EHw_EMmFiVq5IIjGqSl"
                render="explicit"
                // onloadCallback={callback}
                verifyCallback={() => this.setState({ captchaVerified: true })}
              />
              <br />
              <Button
                color="primary"
                type="submit"
                size="lg"
                disabled={buttonDisabledWithCapcha}
              >
                {this.state.buttonText}
              </Button>
            </Form>
          </div>
          <br />
          <br />
          <br />
        </Container>
      </Layout>
    );
  }
}

export default ContactUs;

export const frontmatter = {
  title: 'Contact Us',
  category: 'pages',
  path: '/contactus',
  description: 'Contact Us',
  tags:
    'hq, trung uong, headquarters, chaplain, tuyen uy, officers, tu, bchtu, bch, president, secretary, treasurer, national executive committee, address, email, phone ',
};
